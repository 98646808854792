.partners-section {
	background-color: $white;
	border-bottom: 1px solid $light-grey;
	padding-bottom: 20px;
	.header-text {
		color: $darker-blue;
		margin-bottom: 35px;
	}
}
.partner-item-wrapper {
	margin-bottom: 30px;
	@include respond-to(medium) {
		// margin-bottom: 0;
	}
}