body.homepage {
	.features-tabs-row {
		// border-top: 1px solid $light-grey;
		padding-top: 0;
	}
}
body.homepage {
	.features-section {
		padding: 15px 0;
		h1 {
			// font-size: 36px;
			color: $darker-blue;
			margin-bottom: 0;
		}
	}
}
.homepage-features-tabs-wrapper {
	list-style: none;
	margin-left: 0;
	margin-bottom: 0;
	padding-left: 0;
	// padding-top: 20px;
	li {
		border-top: 1px solid $darker-blue;
		border-bottom: 1px solid $darker-blue;
		border-left: 1px solid $darker-blue;
		height: 60px;
		// padding-top: 10px;
		&:last-child {
			border-right: 1px solid $darker-blue;
		}
		@include respond-to(small) {
			
			height: 60px;
			// padding-top: 15px;
			&:hover {
				background-color: $darker-blue;
				a {
					color: $white;
				}
			}
		}
		a {
			color: $darker-blue;
		}
	}
	.active {
		background-color: $darker-blue;
		a {
			// border-bottom: 7px solid $brand-green;
			color: $white;
			display: inline-block;
			font-size: 24px;
			font-weight: $font-weight-bold;
			// padding-bottom: 10px;
		}
	}
	a {
		// border-bottom: 0px;
		color: #404040;
		font-size: 24px;
		font-weight: $font-weight-normal;
		text-decoration: none;
		transition: none;
	}
}
.homepage-tab-content {
	padding: 15px 0;
}
.homepage-feature-wrapper {
	&.active {
		a {
			// border-bottom: 7px solid $brand-green;
			color: $white;
			display: inline-block;
			font-size: 24px;
			font-weight: $font-weight-bold;
			padding-bottom: 10px;
		}
	}
}
.tab-header-wrapper {
	// color: $brand-green;
	font-size: 14px;
	font-weight: $font-weight-medium;
	height: 80px;
	// padding-top: 20px;
	padding-top: 15px;
	padding-bottom: 10px;
	text-transform: uppercase;
	@include respond-to(small) {
		font-size: 20px;
		height: 60px;
		// opacity: 0.8;
		// &:hover {
		// 	opacity: 1;
		// }
	}
	> span {
		display: block;
		@include respond-to(small) {
			display: inline-block;
		}
	}
}
.tab-text-wrapper {
	font-size: 18px;
	padding-bottom: 15px;
}
.tab-learn-more-wrapper {
	// display: none;
	padding-bottom: 15px;
	a {
		color: $brand-green;
		font-size: 18px;
	}
}