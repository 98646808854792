.footer-menu-wrapper {
	// margin-bottom: 30px;
	li {
		margin-bottom: 10px;
	}
}
.copyright-wrapper {
	color: $white;
	font-size: 14px;
}
.ironpaper-link-wrapper {
	display: none;
	@include respond-to(medium) {
		display: inline-block;
	}
	a {
		color: $brand-blue;
	}
}
.submitted-message {
	color: $white;
}
.footer-form-wrapper {
	// padding-bottom: 30px;
	padding-bottom: 10px;
	> div {
		margin-bottom: 5px !important;
	}
	h3 {
		display: none;
	}
	.hs_email {
		display: inline-block;
		width: 250px;
		label {
			display: block;
		}
		input[type="email"] {
			border-radius: 0px;
		}
		.hs-error-msgs {
			color: $brand-blue;
			position: absolute;
		}
		.hs-form-required {
			display: none;
		}
	}
	.hs_submit {
		border: 1px solid $white;
		display: inline-block;
		position: relative;
		top: -2px;
		@include respond-to(medium) {
			// left: 10px;
		}
		input[type="submit"] {
			background-color: #252525;
			border-radius: 0px;
			border-color: transparent;
			color: $white;
			font-family: $primary-font;
		}
	}
}
.app-stores-wrapper {
	@include respond-to(medium) {
		text-align: right;
	}
}
.footer-social-links {
	padding-bottom: 30px;
	@include respond-to(medium) {
		// padding-top: 35px;
		// margin-top: -40px;
		margin-top: -20px;
		padding-bottom: 0;
	}
	.footer-social-links-inner-wrapper {
		@include respond-to(medium) {
			float: right;
		}
	}
	.link-wrapper {
		a {
			background-color: #333333;
			border-radius: 50%;
			color: $white;
			display: inline-block;
			height: 30px;
			width: 30px;
			i {
				@include vertical-align-center();
				font-size: 15px;
				height: 23px;
				text-align: center;
			}
		}
	}
}
.site-bottom-footer {
	padding: 5px 0;
}