.setup-process-section {
	color: $white;
	.steps-wrapper {
		padding-top: 40px;
		padding-bottom: 40px;
		> div {
			margin-bottom: 30px;
		}
	}
	.step-number-wrapper {
		background-color: $darker-blue;
		border: 3px solid $white;
		border-radius: 50%;
		display: inline-block;
		font-size: 48px;
		height: 70px;
		line-height: 1.3;
		margin-bottom: 20px;
		position: relative;
		width: 70px;
	}
	.step-label-wrapper {
		color: $brand-green;
		font-size: 36px;
		font-weight: $font-weight-medium;
	}
	.step-description-wrapper {
		font-size: 20px;
	}
	.steps-connector-wrapper {
		background-image: url(http://cdn2.hubspot.net/hubfs/2387885/img/dotted-line-green.png);
		background-repeat: repeat-x;
		height: 10px;
		position: absolute;
		right: -310px;
		top: 50%;
		width: 300px;
	}
}