.support-section {
	color: $darker-blue;
	padding-bottom: 60px;
	.header-text-wrapper {
		h2 {
			font-weight: $font-weight-medium;
		}
	}
	.description-wrapper {
		padding-bottom: 40px;
	}
	.section-header-text-separator {
		background-color: $darker-blue;
		height: 7px;
		margin: 30px auto;
		width: 100px;
	}
	.support-panel-outer-wrapper {
		border: 1px solid $brand-green;
		&:hover {
			background-color: $brand-green;

			.icon-wrapper {
				color: $white;
			}
			.support-panel-title-wrapper {
				color: $white;
			}
			.support-panel-divider {
				background-color: $white;
			}
			.support-panel-description-wrapper {
				color: $white;
			}
			.cta-text-wrapper {
				a {
					color: $white;
					transition: none;
				}
			}
		}
	}
	.support-panel-link-wrapper {
		color: $darker-blue;
		text-decoration: none;
	}
	.support-panel-inner-wrapper {
		padding: 30px 45px 20px 45px;		
	}
	.icon-wrapper {
		color: $brand-blue;
		font-size: 40px;
		padding-bottom: 10px;
	}
	.support-panel-title-wrapper {
		font-size: 30px;
		font-weight: $font-weight-medium;
		padding-bottom: 10px;
	}
	.support-panel-divider {
		background-color: $brand-green;
		height: 1px;
		margin: auto;
		width: 200px;
	}
	.support-panel-description-wrapper {
		padding-top: 25px;
		padding-bottom: 40px;
	}
	.cta-text-wrapper {
		bottom: 10px;
		left: 0;
		position: absolute;
		width: 100%;
		a {
			transition: none;
		}
	}
	.support-panels-wrapper {
		> div {
			margin-bottom: 30px;
		}
	}
}