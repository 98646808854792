.signup-page-wrapper {
	height: 100%;
}
.signup-form-wrapper {
	background-color: $white;
	border: 4px solid $brand-blue;
	color: $brand-blue;
	margin: auto;
	padding: 50px;
	width: 90%;
	@include respond-to(small) {
		width: 585px;
	}
	h3 {
		display: none;
	}
	label {
		color: $mid-grey;
		display: block;
		font-weight: $font-weight-normal;
		text-align: left;
	}
	.header-text-wrapper {
		font-size: 24px;
		font-weight: $font-weight-medium;
	}
}