.features-section-header-row {
	padding: 10px 0;
	.header-text-wrapper {
		color: $brand-green;
		h2 {
			font-weight: $font-weight-medium;
		}
		> div {
			display: inline-block;
		}
	}
}
.features-tabs-row {
	// padding-top: 15px;
	@include respond-to(small) {
		// border-bottom: 1px solid $light-grey;
	}
}
.features-page-tabs {
	list-style: none;
	margin-left: 0;
	margin-bottom: 0;
	padding-left: 0;
	li {
		border-top: 1px solid $darker-blue;
		border-bottom: 1px solid $darker-blue;
		border-left: 1px solid $darker-blue;
		height: 60px;
		// padding-top: 10px;
		&:last-child {
			border-right: 1px solid $darker-blue;
		}
		@include respond-to(small) {
			
			height: 60px;
			// padding-top: 15px;
			&:hover {
				background-color: $darker-blue;
				a {
					color: $white;
				}
			}
		}
		@include respond-to(small-down) {
			// height: 60px;
		}
		&.active {
			background-color: $darker-blue;
			a {
				// border-bottom: 7px solid $brand-green;
				// background-color: $light-grey;
				color: $white;
				display: inline-block;
				// font-size: 24px;
				font-weight: $font-weight-bold;
				padding-bottom: 10px;
			}
		}
		a {
			font-size: 14px;
			border-bottom: 0px;
			color: #404040;
			display: block;
			font-weight: $font-weight-normal;
			padding-top: 15px;
			text-decoration: none;
			transition: none;
			// opacity: 0.8;
			// &:hover {
			// 	opacity: 1;
			// }
			@include respond-to(small) {
				// font-size: 24px;
				font-size: 20px;
			}
		}
	}
}
.features-tab-content {
	.tab-intro-text-wrapper {
		font-size: 18px;
		padding-bottom: 30px;
	}
	.cta-text-wrapper {
		// font-size: 24px;
		font-size: 18px;
		// font-weight: $font-weight-medium;
		font-weight: $font-weight-normal;
		padding-bottom: 10px;
		a {
			color: $brand-blue;
		}
	}
}