.slick-arrow-wrapper {
	background-color: $white;
	border-radius: 50%;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
	height: 30px;
	position: relative;
	width: 30px;
	z-index: 200;
	i {
		color: $brand-blue;
		padding: 8px 0 0 8px;
	}
}
.slick-arrow {
	position: absolute;
	top: 50%;
}
.slick-prev {
	left: -25px;
}
.slick-next {
	right: -25px;
	i {
		padding-left: 10px;
	}
}
.individual-feature-slider {
	margin: auto;
	width: 1000px;
	.slick-arrow-wrapper {
		background-color: transparent;
		box-shadow: none;
	}
	.slick-prev {
		left: -35px;
	}
	.slick-next {
		right: -35px;
	}
}
.slide {
	.page-hero-video-wrapper {
		height: auto;
	}
	.ipad-play-button {
		display: inline-block !important;
	}
}
.individual-feature-top-section-slider-wrapper {
	.slide {
		img {
			display: inline-block;
		}
	}
}
.individual-feature-section {
	.slick-prev {
		left: -40px;
	}
	.slick-next {
		right: -40px;
	}
	.slick-arrow-wrapper {
		box-shadow: none;
	}
}