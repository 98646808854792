/**********************************************************************
LAYOUT
**********************************************************************/

@mixin vertical-align-center {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/**********************************************************************
BUTTONS
**********************************************************************/
@mixin btn($bgcolor, $textcolor) {
	background-color: $bgcolor;
	border-bottom: 3px solid darken($bgcolor, 19%);
	border-radius: 7px;
	color: $textcolor;
	display: inline-block;
	font-size: 25px;
	font-weight: $font-semibold-weight;
	height: auto;
	min-height: 60px;
	line-height: 1.7;  
	min-width: 235px;
	padding: 10px 30px;
	text-align: center;
	text-shadow: 0 2px 0 rgba(228, 167, 56, 0.004);
	text-transform: uppercase;
	@include respond-to(medium) {
		height: 60px;
	}
}

@mixin respond-to($breakpoint) {
	@if $breakpoint == "small-down" {
		@media all and (max-width: 767px) {
			@content;
		}
	}
	@if $breakpoint == "small" {
		@media all and (min-width: 768px) {
			@content;
		}
	}
	@if $breakpoint == "medium-down" {
		@media all and (max-width: 991px) {
			@content;
		}
	}
	@else if $breakpoint == "medium" {
		@media all and (min-width: 992px) {
			@content;
		}
	}
	@else if $breakpoint == "large" {
		@media all and (min-width: 1200px) {
			@content;
		}
	}
	@else if $breakpoint == "x-large" {
		@media all and (min-width: 1440px) {
			@content;
		}
	}
}