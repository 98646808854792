.impact-numbers-section {
	.header-text-wrapper {
		color: $darker-blue;
		padding-bottom: 40px;
		h2 {
			font-weight: $font-weight-medium;
		}
	}
	.stats-wrapper {
		padding-bottom: 40px;
	}
	.stat-wrapper {
		margin-bottom: 30px;
		.graphic-wrapper {
			background-size: contain;
			height: 125px;
			width: auto;
			span {
				color: $darker-blue;
				display: inline-block;
				font-size: 42px;
				padding-top: 28px;
			}
		}
		.description-wrapper {
			font-size: 24px;
			padding-top: 30px;
		}
	}
}