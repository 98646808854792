.lp-form-wrapper {
	background-color: $offwhite;
	border: 1px solid $light-grey;
	padding: 20px;
	label {
		display: block;
		text-align: left;
	}
	.hs_submit {
		input {
			background-color: $darker-blue;
			color: $white;
			display: block;
			width: 100%;
			&:hover {
				background-color: transparent;
				color: $darker-blue;
			}
		}
	}
}