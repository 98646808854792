.benefits-section {
	position: relative;
	.header-text-wrapper {
		color: $brand-blue;
	}
	.underline {
		background-color: $brand-blue;
	}
	.benefits-bg-image {
		display: none;
		margin-top: -20px;
		position: absolute;
		right: 0;
		top: -5px;
		@include respond-to(medium) {
			display: block;
			width: 50%;
		}
		@include respond-to(large) {
			// max-height: 685px;
			// max-height: 575px;
			max-height: 595px;
			width: auto;
		}
	}
	.benefits-items-wrapper {
		padding-top: 20px;
	}
	.benefit-item-wrapper {
		margin-bottom: 23px;
	}
	.benefit-icon-wrapper {
		// float: left;
		// display: table-cell;
		padding-top: 10px;
		padding-right: 20px;
		position: relative;
		img {
			
		}
	}
	.benefit-text-wrapper {
		color: $white;
		// display: table-cell;
		// float: left;
	}
	.benefit-headline-wrapper {
		// font-size: 24px;
		font-size: 18px;
		font-weight: $font-weight-medium;
	}
	.benefit-description-wrapper {
		// font-size: 18px;
		font-size: 16px;
		font-weight: $font-weight-normal;
	}
	.benefits-cta-wrapper {
		margin-bottom: 75px;
		a {
			display: inline-block;
		}
	}
}
