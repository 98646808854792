.feature-page-cta-section {
	padding: 15px 0;
}
.cta-panels-wrapper {
	position: relative;
}
.cta-panel-outer-wrapper {
	background-color: $offwhite;
	border: 3px solid #2c3244;
	position: relative;
}
.cta-panel-inner-wrapper {
	padding: 60px 30px;
	@include respond-to(medium) {
		padding: 50px 130px;
	}
}
.cta-panel-title-wrapper {
	color: $brand-green;
	padding-bottom: 30px;
	h2 {
		font-weight: $font-weight-medium;
	}
}
.cta-panel-description-wrapper {
	padding-bottom: 30px;
}
.or-wrapper {
	background-color: $offwhite;
	border: 2px solid $light-grey;
	border-radius: 50%;
	font-size: 36px;
	font-weight: $font-weight-normal;
	height: 70px;
	// line-height: 1.7;
	padding-top: 7px;
	width: 70px;
	z-index: 50;
	@include respond-to(medium) {
		@include vertical-align-center();
		position: absolute;
		right: -35px;
	}	
}