.statistics-testimonials-section {
	background-color: $offwhite;
	position: relative;
}
.homepage-statistic-inner-wrapper {
	margin: auto;
	position: relative;
	width: 100%;
	@include respond-to(medium) {
		width: 710px;
	}
}
.statistic-label {
	color: $darker-blue;
	// font-size: 36px;
	font-size: 30px;
}
.statistic-number-wrapper {
	color: $brand-blue;
	font-size: 75px;
	font-weight: $font-weight-light;
	@include respond-to(small) {
		font-size: 100px;
	}
}
.statistic-plus {
	display: none;
	position: absolute;
	@include respond-to(medium) {
		display: block;
		&.statistic-plus-left {
			left: 100px;
		}
		&.statistic-plus-right {
			right: 100px;
		}
	}
}