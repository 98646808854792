.three-features-section {
	// padding-bottom: 40px;
	.feature-wrapper {
		font-size: 24px;
		font-weight: $font-weight-medium;
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 0;
		}
		@include respond-to(medium) {
			margin-bottom: 0;
		}
		img {
			height: 35px;
			width: auto;
		}
	}
}