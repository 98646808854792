.middot-wrapper {
	display: none;
	@include respond-to(medium) {
		display: inline-block;
	}
}
.post-title {
	a, h1 {
		font-size: 36px;
		// font-weight: $font-weight-medium;
		font-weight: $font-weight-normal;
		line-height: 1.2;
	}
	a {
		color: $darker-blue;
		text-decoration: none;
		&:hover {
			color: $brand-blue;
		}
	}
}
.author-name-wrapper {
	color: $brand-green;
	display: inline-block;
	font-weight: $font-weight-medium;
	@include respond-to(medium-down) {
		width: 100%;
	}
}
.post-date-wrapper {
	color: #cccccc;
	display: inline-block;
	font-weight: $font-weight-medium;
	@include respond-to(medium) {
		padding: 0 20px 0 10px;
	}
	@include respond-to(medium-down) {
		width: 100%;
	}
}
.share-post-wrapper, .time-read-wrapper {
	color: $brand-blue;
	font-size: 18px;
	padding-right: 20px;
}
.posts-inner-wrapper {
	@include respond-to(medium) {
		padding-right: 50px;
	}
}
.hero-post-wrapper {
	border-bottom: 1px solid;
	padding-bottom: 50px;
	// padding-top: 50px;
	.featured-image-wrapper {
		padding-bottom: 30px;
	}
	.post-title {

	}
	.author-name-wrapper {
		font-size: 18px;
	}
	.post-date-wrapper {
		font-size: 18px;
	}	
}
.hero-post-meta-wrapper {
	padding-bottom: 25px;
}
.standard-post-wrapper {
	border-bottom: 1px solid;
	padding-bottom: 50px;
	padding-top: 50px;
	.post-title {
		margin-bottom: 20px;
		a {
			font-size: 24px;
			font-weight: $font-weight-medium;
		}
	}
	.author-name-wrapper {
		font-size: 18px;
	}
	.post-date-wrapper {
		font-size: 18px;
	}
	.more-link-wrapper {
		display: inline-block;
		margin-right: 20px;
	}
	.post-excerpt-wrapper {
		padding: 15px 0;
	}
}
.csp-pagination {
	float: right;
	list-style: none;
	margin-left: 0;
	margin-top: 35px;
	padding-left: 0;
	li {
		display: inline-block;
		margin-right: 5px;
	}
}