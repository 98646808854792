body.contact-us-page {
	.left-column-inner-wrapper {
		padding-right: 50px;
	}
}
.contact-top-section-wrapper {
	padding-bottom: 50px; 
}
.form-section-content-wrapper {
	padding-bottom: 50px;
}

.contact-form-section-wrapper {
	.form-wrapper {
		h3 {
			display: none;
		}
		.hs-form-field {
			label {
				color: $brand-blue;
				display: block;
				font-size: 18px;
				font-weight: 400;
			}
		}
		.hs_submit {
			input[type="submit"] {
				background-color: $brand-blue;
				border: 0px;
				color: $white;
				font-family: $primary-font;
				font-size: 18px;
				padding: 10px 50px;
				&:hover {
					border: 0px;
				}
			}
		}
	}
}
body.contact-us-page {
	.sidebar-section {
		padding-bottom: 50px;
	}
}