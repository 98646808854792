.testimonial-outer-wrapper {
	padding: 15px;
	&:focus {
		outline: none;
	}
	@include respond-to(small) {
		padding: 25px;
	}
}
.testimonial-inner-wrapper {
	box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.1);
	padding-top: 5px;
	position: relative;
	.tint {
		background-color: $brand-blue;
		display: none;
		.testimonial-text-wrapper {
			color: $white;
		}
	}
	&:hover {
		.tint {
			display: block;
		}
		.testimonial-name-wrapper {
			color: $white;
		}
	}
}
.testimonial-image-wrapper {
	border-radius: 50%;
	height: 180px;
	margin: 25px auto;
	width: 180px;
	@include respond-to(small) {
		height: 200px;
		width: 200px;
	}
}
.testimonial-name-wrapper {
	color: $brand-blue;
	padding-bottom: 25px;
	position: relative;
	z-index: 100;
}
.testimonial-company-wrapper {
	color: $darker-blue;
	position: relative;
	z-index: 100;
}
.testimonial-location-wrapper {
	color: $darker-blue;
	padding-bottom: 25px;
	position: relative;
	z-index: 100;
}
.testimonial-text-wrapper {
	color: $white;
	padding: 40px 35px 0px;
}
#homepage-testimonials-slider {
	padding: 0 50px;
	.hs_cos_wrapper.slick-slide {
		&:focus {
			outline: none;
		}
	}
	.slick-arrow-wrapper {
		background-color: $white;
		border-radius: 50%;
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
		height: 30px;
		position: relative;
		width: 30px;
		z-index: 200;
		i {
			color: $brand-blue;
			padding: 8px 0 0 8px;
		}
	}
	.slick-arrow {
		position: absolute;
		top: 50%;
	}
	.slick-prev {
		left: -25px;
	}
	.slick-next {
		right: -25px;
		i {
			padding-left: 10px;
		}
	}
}