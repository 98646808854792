.breadcrumb-wrapper {
	color: $darker-blue;
	font-size: 18px;
	padding: 20px 0;
}
.blog-post-title-wrapper {
	font-size: 36px;
}
.post-outer-wrapper {
	padding-right: 50px;
}
.post-navigation-outer-wrapper {
	padding-top: 50px;
	padding-bottom: 100px;
}
.post-navigation-link {
	img {
		height: 23px;
		width: auto;
	}
}
body.blog-post, body.blog {
	.share-this-label {
		color: $brand-blue;
		display: inline-block;
		font-size: 18px;
		font-weight: $font-weight-normal;
		padding-top: 50px;
		position: relative;
		top: 7px;
	}
	.social-inner-wrapper {
		.link-wrapper {
			background-color: #333333;
			border-radius: 50%;
			color: $white;
			cursor: pointer;
			display: inline-block;
			height: 35px;
			margin-right: 15px;
			width: 35px;
			i {
				@include vertical-align-center();
				font-size: 20px;
			}
		}
	}
}
.share-post-wrapper {
	cursor: pointer;
	position: relative;
}
.popup-share-wrapper {
	// background-color: rgba(255, 255, 255, 1);
	background-color: $brand-blue;
	// border-left: 2px solid $brand-blue;
	display: none;
	left: -50px;
	padding: 15px;
	position: absolute;
	text-align: center;
	width: 200px;
	&:before {
	    bottom: 100%;
	    left: 50%;
	    border: solid transparent;
	    content: " ";
	    height: 0;
	    width: 0;
	    position: absolute;
	    pointer-events: none;
	    border-color: rgba(255, 255, 255, 0);
	    border-bottom-color: $brand-blue;
	    border-width: 8px;
	    margin-left: -1px;
	}
	.link-wrapper {
		background-color: transparent !important;
	}
}