.top-menu-wrapper {
	background-color: $dark-grey;
	.items-wrapper {
		// width: 225px;
		width: 315px;
	}
}
.header-phone-wrapper {
	color: $white;
	// cursor: pointer;
	float: left;
	font-size: 14px;
	padding: 5px 10px;
	position: relative;
	.tooltip-content {
		left: -33px;
		top: 50px;
	}
	i {
		font-size: 14px;
		margin-right: 10px;
	}
	// &:hover {
	// 	background-color: $brand-blue;
	// }
}
.header-login-wrapper {
	cursor: pointer;
	float: left;
	font-size: 13px;
	padding: 4px 10px;
	// text-transform: uppercase;
	// width: 75px;
	// &:hover {
	// 	background-color: $brand-green;
	// }
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			font-weight: $font-weight-bold;
		}
	}
}
.header-hiring-wrapper {
	float: left;
	padding: 4px 10px;
}
body.blog-post, body.pricing-page, body.error-404 {
	#cstore-main-menu-wrapper {
		a {
			color: $brand-blue;
		}
		.active {
			a {
				color: $brand-blue;
			}
		}
	}
}
#cstore-main-menu-wrapper {
	// background-color: rgba(15, 54, 91, 1);
	background-color: $dark-grey;
	display: none;
	float: left;
	margin-bottom: 0;
	padding-top: 10px;
	@include respond-to(small-down) {
		border-radius: 0px;
		left: 0;
		min-height: 400px;
		padding: 50px 25px;
		position: absolute;
		top: -42px;
		width: 100%;
		z-index: 100;
	}
	@include respond-to(small) {
		background-color: transparent;
		display: block;
		float: right;
	}
	#hs_menu_wrapper_my_menu {
		float: left;
	}
	.hs-menu-depth-1 {
		margin-right: 50px;
		@include respond-to(small-down) {
			margin-bottom: 15px;
			width: 100%;
		}
		a {
			color: $white;
			font-size: 16px;
			font-weight: $font-weight-normal;
			text-decoration: none;
			transition: all 0.1s ease;
			// text-transform: uppercase;
			&:hover {
				// color: $brand-blue;
				font-weight: $font-weight-bold;
			}
		}
		&.active {
			> a {
				color: $brand-blue;
				@include respond-to(medium) {
					color: $white;
					border-bottom: 5px solid $brand-blue;
				}
			}
		}
	}
	// .hs-menu-wrapper {
	// 	.hs-menu-flow-horizontal {
	// 		> ul {
	// 		li.hs-item-has-children {
	// 			ul.hs-menu-children-wrapper {
	// 				@include respond-to(medium-down) {
	// 					visibility: visible;
	// 				}
	// 			}
	// 		}
	.hs-menu-children-wrapper {
		background-color: $dark-grey;
		padding-left: 20px;
		@include respond-to(medium) {
			padding: 25px 20px;
		}
		.hs-menu-depth-2 {
			display: inline-block;
			padding-bottom: 10px;
			width: 100%;
			&.active {
				a {
					font-weight: $font-weight-bold;
				}
			}
		}
	}
	.hs-cta-wrapper {
		float: left;
		@include respond-to(small-down) {
			clear: left;
		}
	}
}
.white-cta-button {
	border: 1px solid $white;
	color: $white;
	font-size: 16px;
	font-weight: $font-weight-medium;
	padding: 10px;
	text-decoration: none;
	text-transform: uppercase;
	@include respond-to(small) {
		margin-top: -10px;
	}
	&:hover {
		background-color: $white;
		color: $darker-blue;
		text-decoration: none;
	}
}
body.blog-post, body.pricing-page, body.error-404 {
	.white-cta-button {
		border: 1px solid $brand-blue;
		&:hover {
			background-color: $brand-blue;
			color: $white !important;
			text-decoration: none;
		}
	}
}
.hamburger-wrapper {
	position: absolute;
	right: 0;
	z-index: 200;
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: $white;
	}
	.hamburger:focus,
	.hamburger:hover {
		outline: none;
		border: none;
		opacity: 1;
	}
}
body.pricing-page, body.blog-post, body.error-404 {
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: $dark-grey;
	}
	.hamburger.is-active {
		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			background-color: $white;
		}
	}
}
// #main-nav-floating-wrapper {
// 	background-color: $white;
// 	display: none;
// 	z-index: 1000;
	
// 	.cstore-logo {
// 		height: 35px;
// 		width: auto;
// 	}
// 	#cstore-main-menu-wrapper-floating {
// 		margin-bottom: 0;
// 		.hs-menu-depth-1 {
// 			a {
// 				color: $brand-blue;
// 			}
// 		}
// 	}
// 	.main-nav-floating-wrapper-inner {
// 		padding-top: 20px;
// 		padding-bottom: 0;
// 	}
	
// }
.tooltip-content {
	background-color: rgba(51,58,66,1);
	color: $white;
	display: none;
	left: -73px;
	padding: 10px;
	position: absolute;
	top: 30px;
	width: 150px;
	&:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(51,58,66,0);
		border-bottom-color: #333A42;
		border-width: 8px;
		margin-left: -1px;
	}
}
#main-nav-outer-wrapper {
	background-color: transparent;
	left: 0;
	// margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	transition: all 0.4s ease;
	width: 100%;
	z-index: 100;
	.call-us-wrapper {
		float: left;
		margin-left: 40px;
		position: relative;
		visibility: hidden;
		i {
			font-size: 20px;
		}
	}
}
#main-nav-outer-wrapper.cstore-sticky-nav {
	background-color: $white;
	// padding-top: 10px;
	// padding-bottom: 10px;
	position: fixed;
	top: 0;
	transition: all 0.4s ease;
	z-index: 500;
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: $dark-grey;
	}
	.main-nav-inner-wrapper {
		padding-left: 0;
		padding-right: 0;
		@include respond-to(small) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	#cstore-main-menu-wrapper {
		@include respond-to(medium-down) {
			background-color: $white;
		}
	}
	.hs-menu-depth-1 {
		a {
			color: $dark-grey;
			transition: all 0.1s ease;
		}
	}
	.white-cta-button {
		background-color: $brand-green;
		border: 1px solid $brand-green;
		color: $white;
		font-size: 16px;
		font-weight: $font-weight-medium;
		padding: 10px;
		text-decoration: none;
		text-transform: uppercase;
	}
	.call-us-wrapper {
		visibility: visible;
	}
	.hs-menu-children-wrapper {
		background-color: $white;
		.hs-menu-depth-2 {
			a {
				color: $brand-blue;
				&:hover {
					color: $dark-grey;
				}
			}
		}
	}
}
