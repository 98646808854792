.hs-form-field {
	label {
		display: none;
	}
}
body {
	input[type="text"], 
	input[type="password"],
	input[type="email"] {
		&:focus {
			border: 1px solid #cccccc;
			box-shadow: none;
			outline: none;
		}
	}
}