//**********************************************************************/
// Colors
//**********************************************************************/

// Blues
// $brand-blue: #00a5cb;
$brand-blue: #109ad4;
$darker-blue: #0f365b;
$blue-grey: #2e3c49;

// Greens
$brand-green: #9ccb3b;

// Greys
$light-grey: #d7d7d7;
$mid-grey: #464646;
$dark-grey: #1E1E1E;

// Whites
$offwhite: #fafafa;
$white: #ffffff;


//**********************************************************************/
// Fonts
//**********************************************************************/

// Font Stacks
$primary-font: 'Roboto', Helvetica, Arial, sans-serif;

// Font Weights
$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;



$contain-padding: 40px 15px;