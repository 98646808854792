body.why-choose-us-template {
	.header-text-wrapper {
		h2, .h2 {
			font-weight: $font-weight-medium;
		}
	}
	.intro-section {
		.header-text-wrapper {
			padding-bottom: 20px;
			h2 {
				color: $brand-blue;
			}
		}
	}
	.intro-section-content {
		margin-bottom: 50px;
	}
	.team-member-image-wrapper {
		background-size: contain;
		border-radius: 50%;
		height: 190px;
		margin: auto;
		width: 190px;
	}
	.quotation {
		font-size: 30px;
		font-style: italic;
		font-weight: $font-weight-bold;
		position: absolute;
	}
	#left-quote {
		left: -10px;
		top: -10px;
	}
	#right-quote {
		right: 5px;
		top: -10px;
	}
	.three-column-section {
		color: $white;
		padding-bottom: 50px;
		.subheader-text-wrapper {
			padding-bottom: 50px;
		}
		.icon-wrapper {
			float: left;
			padding-top: 10px;
			width: 55px;
		}
		.label-wrapper {
			color: $brand-blue;
			float: none;
			font-size: 24px;
			height: 70px;
			line-height: 62px;
			overflow: hidden;
			padding-left: 15px;
			position: relative;
			width: auto;
			span {
				display: inline-block;
				font-weight: $font-weight-medium;
				line-height: normal;
				vertical-align: middle;
			}
		}
		.column-content-wrapper span {
			font-size: 18px;
			line-height: 1.3;
		}
	}
	.data-security-section {
		.header-text-wrapper {
			color: $darker-blue;
			margin-bottom: 15px;
		}
		.data-security-content-wrapper span {
			font-size: 18px;
			line-height: 1.3;
		}
	}
	.support-section {
		.subheader-text-wrapper {
			margin-bottom: 50px;
		}
		.person-outer-wrapper {
			margin-bottom: 30px;
			padding: 0 25px;
		}
		.person-inner-wrapper {
			border: 2px solid $light-grey;
			margin: auto;
			max-width: 200px;
		}
		.person-image {
			height: 200px;
			margin: auto;
			width: 197px;
		}
		.person-details {
			border-top: 2px solid $light-grey;
			padding: 5px 0;
		}
		.support-feature-outer-wrapper {
			padding: 0 25px;
		}
		.icon-wrapper {
			float: left;
			width: 40px;
		}
		.label-wrapper {
			color: $brand-green;
			float: left;
			font-size: 24px;
			height: 70px;
			line-height: 62px;
			overflow: hidden;
			padding-left: 15px;
			position: relative;
			width: auto;
			span {
				display: inline-block;
				font-weight: $font-weight-medium;
				line-height: normal;
				vertical-align: middle;
			}
		}
		.support-feature-content-wrapper {
			float: left;
			span {
				color: $mid-grey;
				font-size: 18px;
				line-height: 1.3;
			}
		}
	}
	.customers-section {
		color: $darker-blue;
		a {
			text-decoration: none;
		}
		.subheader-text-wrapper {
			font-size: 18px;
		}
	}
	#benefits-customers-slider {
		padding: 180px 0 0 0;
		.customer-outer-wrapper {
			padding: 0 25px;
		}
		.customer-inner-wrapper {
			background-color: $white;
			border: 2px solid rgb(215, 215, 215);
			box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.2);
			padding: 100px 15px;
		}
		.hidden-content {
			padding-top: 20px;
			@include respond-to(medium) {
				display: none;
			}
		}
		.customer-image-wrapper {
			border-radius: 50%;
			height: 120px;
			margin: auto;
			margin-bottom: 25px;
			width: 120px;
		}
		.customer-company-wrapper {
			color: $brand-blue;
			font-size: 22px;
			font-weight: $font-weight-medium;
		}
		.customer-name-wrapper, .customer-position-wrapper {
			color: $darker-blue;
			font-size: 24px;
			font-weight: $font-weight-medium;
			// display: inline-block;
		}
		.customer-bio-wrapper {
			color: $darker-blue;
			padding-bottom: 20px;
		}
		.store-number-wrapper, .employee-number-wrapper, .years-number-wrapper {
			display: inline-block;
			font-size: 14px;
			text-align: center;
			width: 32%;
			div {
				color: $brand-blue;
				font-size: 24px;
			}
		}
		.store-number-wrapper, .employee-number-wrapper {
			border-right: 1px solid $darker-blue;
		}
		.read-more-wrapper {
			margin-top: 20px;
		}
		.slick-track {
			padding: 50px 0;
		}
		.slick-arrow {
			@include respond-to(medium) {
				top: 250px;
			}
		}
		.slick-slide {
			transition: transform 300ms ease;
			&:focus {
				outline: 0;
			}
		}
		.slick-center {
			.customer-inner-wrapper {
				padding-top: 25px;
				padding-bottom: 25px;
				transform: scale(1.18);
				transition: transform 300ms ease;
			}
			.customer-image-wrapper {
				height: 150px;
				width: 150px;
			}
			.customer-company-wrapper {
				display: none;
			}
			.hidden-content {
				display: block;
			}
		}
	}
}