.homepage-cta-section {
	.header-text {
		color: $white;
		padding-bottom: 30px;
	}
	.cta-section-tagline-wrapper {
		color: $white;
		font-size: 18px;
		padding-top: 20px;
	}
}