.blog-sidebar-outer-wrapper {
	padding-top: 50px;
	@include respond-to(medium) {
		padding-top: 0;
	}
}
.subscribe-form-outer-wrapper {
	background-color: $offwhite;
	border: 2px solid $light-grey;
	margin-bottom: 40px;
	padding: 30px;
	.header-text-wrapper {
		padding-bottom: 30px;
		span {
			border-bottom: 1px solid $brand-blue;
			color: $brand-blue;
			display: inline-block;
			font-size: 22px;
			font-weight: $font-weight-medium;
			padding-bottom: 15px;
		}
	}
	form {
		.hs_submit {
			input {
				background-color: $brand-blue;
				border: 0px;
				color: $white;
				font-family: $primary-font;
				white-space: normal;
				width: 100%;
				&:hover {
					border: 0px;
				}
			}
		}
	}
}
.blog-sidebar-social-wrapper {
	border-bottom: 1px solid $light-grey;
	border-top: 1px solid $light-grey;
	margin-bottom: 40px;
	padding: 30px 0;
	.link-wrapper {
		a {
			background-color: #333333;
			border-radius: 50%;
			color: $white;
			display: inline-block;
			height: 50px;
			width: 50px;
			i {
				@include vertical-align-center();
				font-size: 30px;
			}
		}
	}
}
.topics-wrapper {
	padding-bottom: 40px;
	.header-text-wrapper {
		span {
			color: $brand-blue;
			display: inline-block;
			font-size: 18px;
			font-weight: $font-weight-medium;
			padding-bottom: 15px;
		}
	}
	.topic-wrapper {
		background-color: #ebebeb;
		margin-bottom: 5px;
		padding: 5px 10px;
	}
}
.whitepaper-wrapper {
	background-color: $brand-blue;
	background-image: url(http://cdn2.hubspot.net/hubfs/2387885/img/download-whitesheet.png);
	background-repeat: no-repeat;
	background-position: center 60px;
	height: 250px;
	span {
		display: inline-block;
		padding-top: 5px;
		text-align: center;
		width: 100%;
		a {
			color: $white;
			font-size: 18px;
			text-decoration: underline;
		}
	}
}