body.about-us-template {
	.subpage-hero {
		position: relative;
	}
	.city-wrapper {
		bottom: 10px;
		color: $white;
		position: absolute;
		right: 40px;
	}
	.intro-text-section {
		h1 {
			color: $brand-blue;
		}
	}
	.our-team-section {
		.header-text-wrapper {
			color: $darker-blue;
		}
		.subheader-text-wrapper {
			color: $darker-blue;
			margin: auto;
			max-width: 450px;
		}
		.team-members-outer-wrapper {
			padding-top: 50px;
		}
		.team-member-outer-wrapper {
			margin-bottom: 50px;
		}
		.team-member-image-wrapper {
			// border-radius: 50%;
			height: 180px;
			margin: auto;
			width: 180px;
		}
		.team-member-info-wrapper {
			padding-top: 25px;
			text-align: center;
			@include respond-to(small) {
				padding-top: 0;
				text-align: left;
			}
		}
		.team-member-name-wrapper {
			color: $brand-blue;
			font-size: 20px;
			// padding-bottom: 5px;
		}
		.team-member-position-wrapper {
			color: $brand-green;
			padding: 0 0 5px 0;
		}
		.team-member-linkedin-wrapper {
			padding: 5px 0 5px 0;
			a {
				background-color: $dark-grey;
				border-radius: 50%;
				display: inline-block;
				height: 30px;
				padding-top: 3px;
				text-align: center;
				width: 30px;
				i {
					color: $white;
				}
			}
		}
		.team-member-bio-wrapper {
			padding: 5px 0 0 0;
		}
	}
	.partners-section {
		.header-text-wrapper {
			color: $darker-blue;
		}
		.subheader-text-wrapper {
			color: $darker-blue;
			margin: auto;
			max-width: 300px;
			padding-bottom: 50px;
		}
		.partner-items-wrapper {
			margin-left: 1%;
		}
		.partner-item-wrapper {
			display: inline-block;
			padding: 0 15px;
			width: 49%;
			&:last-of-type {
				margin-left: 25.5%;
			}
			@include respond-to(small) {
				width: 32%;
				&:last-of-type {
					margin-left: 34%;
				}
			}
			@include respond-to(medium) {
				width: 24%;
				&:last-of-type {
					margin-left: 0;
				}
				&:nth-of-type(5) {
					margin-left: 14%;
				}
			}
			@include respond-to(large) {
				width: 13.5%;
				&:nth-of-type(5) {
					margin-left: 0;
				}
			}
		}
	}
}