.homepage-hero {
	height: auto;
	@include respond-to(medium) {
		height: 940px;
	}
	.hero-section-wrapper {
		padding-top: 60px
	}
	.h1 {
		color: $white;
	}
}
.blog-hero {
	height: auto;
	@include respond-to(medium) {
		height: 410px;
	}	
	.hero-section-wrapper {
		padding-top: 60px
	}
}
.subpage-hero {
	height: auto;
	@include respond-to(medium) {
		height: 410px;
	}
	.hero-section-wrapper {
		padding-top: 60px
	}
}
.pricing-page {
	.hero-section-wrapper {
		padding-top: 50px;
	}
}
.hero-section-wrapper {
	padding-top: 100px
}
.page-hero-header-text-wrapper {
	h1, .h1 {
		color: $white;
	}
}
.page-hero-header-text-separator {
	background-color: $white;
	height: 7px;
	margin: 30px auto;
	width: 100px;
}
.page-hero-subheader-text-wrapper {
	color: $white;
	font-size: 20px;
	padding-bottom: 30px;
}
.page-hero-cta-wrapper {
	padding-bottom: 30px;
	a {
		display: inline-block;
	}
}
.page-hero-video-wrapper {
	background-position: top center;
	background-size: contain;
	height: 240px;
	margin: 30px auto 0 auto;
	position: relative;
	width: auto;
	@include respond-to(small) {
		height: 350px;
	}
	@include respond-to(medium) {
		background-size: cover;
		height: 500px;
	}
	.ipad-play-button {
		@include vertical-align-center();
	}
}