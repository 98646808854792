html, body {
	background-color: $white;
	font-family: $primary-font;
	font-size: 16px;
	height: inherit;
}
a {
	color: $brand-blue;
}
body.blog {
	background-color: $white;
}
body.homepage {
	background-color: $offwhite;
}
.blue-grey-row {
	background-color: $blue-grey;
}
.dark-blue-row {
	background-color: $darker-blue;
}
.dark-grey-row {
	background-color: $dark-grey;
}
.mid-grey-row {
	background-color: $mid-grey;
}
.light-grey-row {
	background-color: $light-grey;
}
.white-row {
	background-color: $white;
}
.offwhite-row {
	background-color: $offwhite;
}
.border-bottom--light-grey {
	border-bottom: 1px solid $light-grey;
}
.border-bottom--darker-blue {
	border-bottom: 1px solid $darker-blue;
}
//**********************************************************************/
// Layout
//**********************************************************************/		
.contain {
	max-width: 1200px;
	margin: 0 auto;
	padding: $contain-padding;
	&.no-padding {
		padding: 0 0;
	}
	&.no-padding-top {
		padding-top: 0;
	}
	&.no-padding-right {
		padding-right: 0;
	}
	&.no-padding-bottom {
		padding-bottom: 0;
	}
	&.no-padding-left {
		padding-left: 0;
	}
}
.no-padding-right {
	padding-right: 0 !important;
}
.no-padding-left {
	padding-left: 0 !important;
}
.no-padding-left-mobile {
	@include respond-to(small-down) {
		padding-left: 0 !important;
	}
}
.no-padding-right-mobile {
	@include respond-to(small-down) {
		padding-right: 0 !important;
	}
}
.padding-left-mobile {
	@include respond-to(medium-down) {
		padding-left: 15px !important;
	}
 }
.padding-right-mobile {
	@include respond-to(medium-down) {
		padding-right: 15px !important;
	}
 }
.contain-narrow {
	@extend .contain;
	max-width: 800px;
	padding: $contain-padding;
}
.no-padding-left {
	padding-left: 0;
}
.fixed {
	left: 0;
	position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

//**********************************************************************/
// Typography
//**********************************************************************/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $primary-font;
}
h1, .h1 {
	// font-size: 48px;
	font-size: 42px;
	// font-weight: $font-weight-light;
	font-weight: $font-weight-normal;
}
h2, .h2 {
	font-size: 36px;
	// font-weight: $font-weight-light;
	font-weight: $font-weight-normal;
}

//**********************************************************************/
// Utilities
//**********************************************************************/
.bg-image {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.horizontal-menu {
	list-style: none;
	li {
		display: inline-block;
	}
}
.hs-cta-wrapper {
	a {
		display: inline-block;
	}
}
.is-hidden {
	display: none !important;
}
.is-visible {
	display: block !important;
}
.menu-first-blue {
	ul {
		li {
			&:nth-of-type(1) {
				a {
					color: $brand-blue;
				}
			}
			a {
				color: $white;
			}
		}
	}
}
.menu-first-two-blue {
	ul {
		li {
			&:nth-of-type(1), &:nth-of-type(2) {
				a {
					color: $brand-blue;
				}
			}
			a {
				color: $white;
			}
		}
	}
}
.menu-all-blue {
	ul {
		li {
			a {
				color: $brand-blue;
			}
		}
	}
}
.table-cell {
	display: table-cell;
}
.tint {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}
.underline {
	height: 1px;
	width: 107px;
}