body.pricing-page {
	.page-header-text-wrapper {
		color: $darker-blue;
	}
	.page-subheader-text-wrapper {
		color: $darker-blue;
		font-size: 24px;
	}
}
.pricing-table-section {
	.hs-cta-wrapper {
		bottom: 30px;
		@include respond-to(medium) {
			// bottom: 10px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}
.pricing-table-outer-wrapper {
	border: 1px solid $light-grey;
	height: 100%;
	position: relative;
}
.pricing-table-wrapper {
	height: auto;
	margin-bottom: 10px;
	@include respond-to(medium) {
		height: 615px;
	}
}
.pricing-tooltip-wrapper {
	.tooltip-content {
		background-color: rgba(51,58,66,1);
		color: $white;
		display: block;
		left: -73px;
		opacity: 0;
		padding: 10px;
		position: absolute;
		// top: 30px;
		transition: 'opacity 0.25s ease';
		visibility: hidden;
		width: 150px;
		&:before {
			bottom: -16px;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(51,58,66,0);
			border-top-color: #333A42;
			border-width: 8px;
			margin-left: -1px;
		}
	}
	.tooltip-content {
		font-size: 14px;
		left: -200px;
		// top: -70px;
		width: 300px;
		z-index: 100;
		@include respond-to(medium) {
			left: -150px;
		}
		&:before {
			left: 66%;
			@include respond-to(medium) {
				left: 50%;
			}
		}
	}
}
.pricing-table-inner-wrapper {
	padding: 20px 50px 50px;
}
.pricing-table-header-row {
	color: $white;
	// font-size: 36px;
	// font-size: 30px;
	font-size: 24px;
	// font-weight: $font-weight-medium;
	font-weight: $font-weight-normal;
	&.starter-table {
		background-color: $brand-green;
	}
	&.premium-table {
		background-color: $brand-blue;
	}
}
.pricing-table-description-wrapper {
	color: $darker-blue;
	// font-size: 24px;
	font-size: 18px;
	font-weight: $font-weight-normal;
}
.pricing-table-price-wrapper {
	color: $darker-blue;
	font-size: 14px;
	.price-wrapper {
		font-size: 100px;
		font-weight: $font-weight-medium;
		line-height: 1.2;
		sup {
			font-size: 36px;
			top: -41px;
		}
	}
	.price-per-wrapper {
		display: inline-block;
		position: relative;
		top: -5px;
	}
}
.pricing-table-features-list {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-bottom: 30px;
	li {
		font-size: 18px;
		min-height: 25px;
	}
}
.pricing-table-disclaimer-wrapper {
	padding: 20px;
}
.talk-with-person-section, .all-plans-section, .faq-section {
	padding-bottom: 50px;
	.header-text-wrapper {
		color: $darker-blue;
		padding-bottom: 40px;
		h2 {
			font-weight: $font-weight-medium;
		}
	}
}
.talk-with-person-section {
	padding-bottom: 0;
	.header-text-wrapper {
		padding-bottom: 0;
	}
}
.all-plans-section {
	.icons-wrapper {
		> div {
			margin-bottom: 30px;
			@include respond-to(medium) {
				margin-bottom: 0;
			}
		}
		img {
			height: 80px;
			padding-bottom: 20px;
			width: auto;
		}
	}
}
.faq-section {
	.header-text-wrapper {
		padding-bottom: 0;
	}
	.divider {
		background-color: $darker-blue;
		height: 5px;
		margin: auto;
		width: 100px;
	}
}
.faqs-wrapper {
	padding-top: 50px;
}
.faq-wrapper {
	padding-bottom: 30px;
	padding-right: 30px;
}
.question-wrapper {
	color: $brand-green;
	// font-size: 22px;
	font-size: 20px;
	font-weight: $font-weight-medium;
	padding-bottom: 10px;
}
.answer-wrapper {
	font-size: 16px;	
}
.tooltip-wrapper {
	display: inline-block;
}
.tooltip-icon {
	height: 12px;
}
.tooltip-circle {
	border: 1px solid; 
	border-radius: 50%;
	color: $light-grey;
	display: inline-block;
	height: 15px;
	position: relative;
	top: 6px;
	width: 15px;
	i {
		@include vertical-align-center();
		font-size: 10px;
		height: 31px;
	}
}