body.case-study-template {
	.subpage-hero {
		height: auto;
		@include respond-to(small) {
			height: 450px;
		}
		.hero-section-wrapper {
			padding-top: 29px;
			.divider {
				background-color: $white;
				height: 5px;
				width: 100px;
			}
		}
		.team-member-image-wrapper {
			background-size: contain;
			border-radius: 50%;
			height: 190px;
			width: 190px;
		}
		.team-member-image-outer-wrapper {
			margin-bottom: 20px;
		}	
	}
	.customer-stories-label {
		color: $white;
	}
	.case-study-header-text-wrapper {
		color: $brand-blue;
		font-size: 48px;
	}
	.customer-name-wrapper {
		color: $white;
		font-size: 18px;
		font-weight: $font-weight-medium;
	}
	.customer-position-wrapper {
		color: $brand-blue;
	}
	.case-study-stats-section {
		.stat-icon-wrapper {
			color: $brand-green;
			font-size: 36px;
		}
		.stat-number-wrapper {
			color: $brand-blue;
			font-size: 48px;
		}
		.stat-label-wrapper {
			color: $brand-blue;
		}
	}
	.case-study-intro-text-section {
		.intro-text-wrapper {
			color: $darker-blue;
			font-size: 24px;
		}
		.border-bottom--darker-blue {
			border-bottom-width: 5px;
		}
	}
	.quote-section {
		.quote-content-wrapper {
			color: $white;
			font-size: 28px;
			font-style: italic;
			padding-bottom: 25px;
		}
		.divider {
			background-color: $white;
			height: 5px;
			margin: 0 auto 25px;
			width: 115px;
		}
	}
	.quote-author {
		color: $white;
		display: inline-block;
	}
	.quote-company {
		color: $white;
		display: inline-block;
	}
	.section-header-text-wrapper {
		color: $brand-blue;
		font-size: 36px;
		font-weight: $font-weight-medium;
		margin-bottom: 10px;
	}
}