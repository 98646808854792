body.individual-feature-template {
	.section-header-wrapper {
		color: $brand-green;
		font-size: 30px;
		font-weight: $font-weight-normal;
		line-height: 1.3;
		padding-bottom: 10px;
	}
	.section-description-wrapper {
		font-size: 18px;
		font-weight: $font-weight-normal;
		padding-bottom: 30px;
		&.centered-content {
			ul {
				// list-style-position: inside;
				text-align: left;
				margin: auto;
				width: 100%;
				@include respond-to(small) {
					width: 430px;
				}
			}
		}
	}
}
.detail-outer-wrapper {
	margin-bottom: 25px;
}
.detail-inner-wrapper {
	background-color: $white;
	border: 2px solid $light-grey;
	padding: 45px 10px;
	.detail-icon {
		display: table-cell;
		padding-left: 20px;
	}
	.text-wrapper {
		color: $darker-blue;
		display: table-cell;
		font-size: 18px;
		padding-left: 10px;
		vertical-align: middle;
	}
}
.more-details-section {
	h1, .h1 {
		padding-bottom: 30px;
	}
}
#hs_cos_wrapper_individual_feature_top_section {
	.hs_cos_wrapper_type_custom_widget {
		.individual-feature-section {
			border-top: 1px solid $light-grey;
			// padding-top: 30px;
			padding-bottom: 30px;
		}
		&:nth-of-type(even) {
			.individual-feature-section {
				background-color: $offwhite;
			}
		}
		&:last-of-type {
			.individual-feature-section {
				border-bottom: 1px solid $light-grey;
			}
		}
	}
}
.fullwidth-feature-section {
	text-align: left;
	@include respond-to(medium) {
		text-align: center;
	}
	.section-cta-wrapper {
		padding-left: 15px;
		@include respond-to(medium) {
			padding-left: 0;
		}
	}
}
.image-outer-wrapper-left {
	padding-bottom: 30px;
	@include respond-to(medium) {
		padding-right: 50px;
	}
}
.image-outer-wrapper-right {
	padding-bottom: 30px;
	@include respond-to(medium) {
		padding-left: 50px;
	}
}
.individual-feature-section {
	.section-cta-wrapper {
		padding-bottom: 30px; 
	}

}